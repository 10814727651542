import '@styles/global.css';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { store } from './src/store';
import { Provider } from 'react-redux';
import type { GatsbyBrowser } from 'gatsby';
import { ToastContainer } from 'react-toastify';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
const reCaptchaKey = process.env.GATSBY_GOOGLE_RECAPTCHA_PROVIDER;

export const wrapWithProvider: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return (
    <Provider store={store}>
      <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey || ''}>
        {element}
      </GoogleReCaptchaProvider>
      <ToastContainer />
    </Provider>
  );
};
